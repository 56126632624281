<template src="./DigitalPassUpdate.html"></template>

<script>
import { HTTP } from "@/service";
import { Button } from "@progress/kendo-vue-buttons";
import Breadcrumb from "../../../common/AppBreadCrumbs/BreadCrumbs.vue";
import EventTicket from "./templates/EventTicket.vue";
import MembershipCard from "./templates/Membership.vue";
import LoyaltyCard from "./templates/Loyalty.vue";

export default {
  components: {
    Breadcrumb,
    EventTicket,
    MembershipCard,
    LoyaltyCard,
    kbutton: Button,
  },
  props: {
    id: {
      type: String,
    },
    template_id: {
      type: String,
      default: "0",
    },
    title: {
      type: String,
      default: "No Title",
    },
    templateType: {
      type: String,
      default: "1",
    },
  },
  data() {
    return {
      type: "1",
    };
  },
  mounted() {
    this.type = this.$route.query.templateType;
  },
  methods: {},
};
</script>

<style>
</style>