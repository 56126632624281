<template>
  <loader v-if="loader" :size="'large'" :type="'converging-spinner'" />
  <AppSnackBar
    :message="notifyMessage"
    :notificationType="notifyType"
    v-if="isNotify"
  ></AppSnackBar>
  <AppModal
    :class="'max-height-100-145'"
    title="Send Test Email"
    :handler="testEmailModalVisible"
    :cssClass="'c-modal-small'"
    @modalHandler="modalHandler"
  >
    <template v-slot:content>
      <fieldset>
        <label>Send Test Email To:</label>
        <div class="mb-3 form-field">
          <k-input
            :class="'pt-0'"
            :name="'receiverEmail'"
            :type="'email'"
            placeholder="Enter Receiver Email"
            v-model="evData.receiverEmail"
            @keyup="emailErrorMessage = ''"
          >
          </k-input>
          <div class="error-msg">{{ emailErrorMessage }}</div>
        </div>
      </fieldset>
    </template>
    <template v-slot:footer>
      <div class="d-flex justify-content-end">
        <kbutton :class="'me-2'" @click="closeTestEmailModal">Cancel</kbutton>
        <kbutton :theme-color="'primary'" @click="submitTestEmail">
          Submit</kbutton
        >
      </div>
    </template>
  </AppModal>
  <div class="max-height-100--60 overflow-y-auto">
    <div
      class="dashboard-header d-flex flex-wrap justify-content-between align-items-end pb-1 box-shadow-none"
    >
      <div class="d-flex flex-wrap align-items-center">
        <h2 class="font-28 font-34-sm-big font-w-300 me-3">
          {{ this.$route.query.template_id == 0 ? "Create" : "Edit" }} Digital
          Pass
        </h2>
        <span class="event-type blue">Membership</span>
      </div>

      <div class="mt-3 mt-sm-0">
        <kbutton
          :class="'me-2 pl-0-vsm'"
          :fill-mode="'flat'"
          @click="toDigitalPassTemp"
          >Cancel</kbutton
        >
        <kbutton
          :disabled="submitDisable"
          :theme-color="'primary'"
          @click="savePassData"
          >Save Design</kbutton
        >
      </div>
    </div>
    <div class="event-ticket-container">
      <tabstrip
        :selected="selected"
        @select="onSelect"
        :class="'border-tab create-digital-top-tab'"
      >
        <tabstripTab :title="'Google Pass'">
          <!-- <GooglePass /> -->
          <div v-if="!loader" class="row m-0">
            <div class="col-xl-7 col-lg-12 p-0">
              <div class="pass-left-content">
                <tabstrip
                  :selected="googleSideBarSelected"
                  @select="googleSideBarOnSelect"
                  :tabPosition="'left'"
                  :class="'left-side-tab'"
                >
                  <tabstripTab :title-render="appleLeftSidebarLogoArea">
                    <template v-slot:appleLeftSidebarLogoArea="{ props }">
                      <div><span class="cb-logo-area"></span> Logo Area</div>
                    </template>
                    <div>
                      <h4 class="font-19">Images</h4>
                      <p class="color-light2 font-14 mb-32">
                        Add your {{ isClub() }}'s logo to display on the digital
                        pass.
                      </p>
                      <h4 class="font-14 font-w-700">Logo Image</h4>
                      <p class="color-light2 font-14">
                        Logos are mandatory for wallet pass. The recommended
                        dimensions are 660 pixels by 660 pixels.
                      </p>

                      <div class="file-uploader-custom">
                        <div class="uplodade-img-box">
                          <kbutton
                            v-if="evData.google.logo"
                            :icon="'close'"
                            @click="removeGoogleImages('logo')"
                            :size="'small'"
                          ></kbutton>
                          <img
                            v-if="evData.google.logo"
                            :src="evData.google.logo"
                          />
                          <img
                            v-else
                            src="../../../../../assets/images/login-bg.png"
                          />
                        </div>

                        <input
                          title="Select a file"
                          type="file"
                          @change="handleGoogleLogoUpload"
                          accept="image/png, image/jpeg"
                        />
                      </div>
                    </div>
                  </tabstripTab>

                  <tabstripTab
                    @tabRemove="(e) => onTabRemove(e)"
                    :title-render="appleLeftSidebarColor"
                  >
                    <template v-slot:appleLeftSidebarColor="{ props }">
                      <div><span class="cb-colors"></span> Colors</div>
                    </template>

                    <div>
                      <h4 class="font-19">Colors</h4>
                      <p class="text-color-2 mb-32">
                        Choose an attractive background colour for your digital
                        pass.
                      </p>
                      <h4 class="font-14 font-w-700 mb-14">Background color</h4>
                      <div class="color-picker-wrapper">
                        <span
                          :style="{ color: evData.google.googleTextColor }"
                          class="color-name font-16"
                          >{{ evData.google.backgroundColor }}</span
                        >
                        <kendo-colorpicker
                          v-model="evData.google.backgroundColor"
                          @change="googleColorPickerChange"
                          >ColorPicker</kendo-colorpicker
                        >
                      </div>
                    </div>
                  </tabstripTab>

                  <tabstripTab :title-render="appleLeftSidebarHeaderField">
                    <template v-slot:appleLeftSidebarHeaderField="{ props }">
                      <div><span class="cb-top-row"></span> Top Row</div>
                    </template>
                    <div>
                      <h4 class="font-19">Top Row</h4>
                      <p class="text-color-2 mb-32">
                        Basic information about your template.
                      </p>

                      <label for="">Event Name/ Organization Name *</label>
                      <k-input
                        type="text"
                        placeholder="My Organization"
                        v-model="evData.google.header"
                      ></k-input>

                      <label for="">Venue Name/ Sub-Header*</label>
                      <k-input
                        type="text"
                        placeholder="Design Name"
                        v-model="evData.google.subHeader"
                      ></k-input>
                    </div>
                  </tabstripTab>

                  <tabstripTab :title-render="appleLeftSidebarLogoArea">
                    <template v-slot:appleLeftSidebarLogoArea="{ props }">
                      <div><span class="cb-logo-area"></span> Hero Image</div>
                    </template>
                    <div>
                      <radiobutton
                        :size="'large'"
                        :name="'group1'"
                        :value="'first'"
                        :checked="googlePassSelectedValue === 'first'"
                        @change="googlePassHandleChange"
                        :label="'Hero Image'"
                      />
                      <p class="text-color-2 my-12 ps-4">
                        Add a hero image to give members an idea about the
                        purpose of the digital pass. The recommended dimensions
                        are
                        <strong>1032 by 336 pixels.</strong>
                      </p>

                      <div class="file-uploader-custom mb-4 width-437 ms-4">
                        <div class="uplodade-img-box">
                          <kbutton
                            v-if="evData.google.thumbnail"
                            :icon="'close'"
                            @click="removeGoogleImages('thumbnail')"
                            :size="'small'"
                          ></kbutton>
                          <img
                            v-if="evData.google.thumbnail"
                            :src="evData.google.thumbnail"
                          />
                          <img
                            v-else
                            src="../../../../../assets/images/login-bg.png"
                          />
                        </div>
                        <input
                          v-if="googlePassSelectedValue === 'first'"
                          title="Select a file"
                          type="file"
                          @change="handleGoogleThumbnailUpload"
                          accept="image/png, image/jpeg"
                        />
                      </div>

                      <radiobutton
                        :size="'large'"
                        :name="'group1'"
                        :value="'second'"
                        :checked="googlePassSelectedValue === 'second'"
                        @change="googlePassHandleChange"
                        :label="'No Image'"
                      />
                    </div>
                  </tabstripTab>

                  <tabstripTab :title-render="appleLeftSidebarPrimaryField">
                    <template v-slot:appleLeftSidebarPrimaryField="{ props }">
                      <div><span class="cb-second-row"></span> Second Row</div>
                    </template>
                    <div>
                      <h4 class="font-19">Second Row</h4>
                      <p class="text-color-2 mb-24">
                        Select Member Information
                      </p>
                      <card class="width-437 mb-4">
                        <cardBody class="p-0">
                          <div
                            class="py-12 px-16 border-bottom-normal-1 d-flex justify-content-between"
                          >
                            <cardTitle class="font-16 font-w-400 m-0"
                              >First Field</cardTitle
                            >
                            <!-- <kbutton
                              class="p-0 btn-hover-none"
                              :icon="'delete'"
                              :fill-mode="'flat'"
                            ></kbutton> -->
                          </div>
                          <div class="p-24 pb-2">
                            <label for="">Field Label</label>
                            <k-input
                              type="text"
                              placeholder="Field Label"
                              v-model="evData.google.secondRowField1Label"
                            >
                            </k-input>
                            <label for="">Default value</label>
                            <k-input
                              type="text"
                              placeholder="Default value"
                              v-model="evData.google.secondRowField1Value"
                            >
                            </k-input>
                          </div>
                        </cardBody>
                      </card>

                      <card class="width-437 mb-4">
                        <cardBody class="p-0">
                          <div
                            class="py-12 px-16 border-bottom-normal-1 d-flex justify-content-between"
                          >
                            <cardTitle class="font-16 font-w-400 m-0"
                              >Second Field</cardTitle
                            >
                            <!-- <kbutton
                              class="p-0 btn-hover-none"
                              :icon="'delete'"
                              :fill-mode="'flat'"
                            ></kbutton> -->
                          </div>
                          <div class="p-24 pb-2">
                            <label for="">Field Label</label>
                            <k-input
                              type="text"
                              placeholder="Field Label"
                              v-model="evData.google.secondRowField2Label"
                            >
                            </k-input>
                            <label for="">Default value</label>
                            <k-input
                              type="text"
                              placeholder="Default value"
                              v-model="evData.google.secondRowField2Value"
                            >
                            </k-input>
                          </div>
                        </cardBody>
                      </card>
                      <!-- <kbutton
                        class="p-0 mt-26 btn-hover-none color-blue"
                        :fill-mode="'flat'"
                        >+ ADD NEW FIELDS</kbutton
                      > -->
                    </div>
                  </tabstripTab>

                  <tabstripTab :title-render="appleLeftSidebarBack">
                    <template v-slot:appleLeftSidebarBack="{ props }">
                      <div><span class="cb-details"></span> Details</div>
                    </template>
                    <div>
                      <h4 class="font-19">Details</h4>
                      <p class="text-color-2 mb-24">
                        Select card backside information.
                      </p>
                      <card class="width-437 mb-4">
                        <cardBody class="p-0">
                          <div
                            class="py-12 px-16 border-bottom-normal-1 d-flex justify-content-between"
                          >
                            <cardTitle class="font-16 font-w-400 m-0"
                              >First Field</cardTitle
                            >
                            <!-- <kbutton
                              class="p-0 btn-hover-none"
                              :icon="'delete'"
                              :fill-mode="'flat'"
                            ></kbutton> -->
                          </div>
                          <div class="p-24 pb-2">
                            <label for="">Field Label</label>
                            <k-input
                              type="text"
                              placeholder="Field Label"
                              v-model="evData.google.backRowField1Label"
                            >
                            </k-input>
                            <label for="">Default value</label>
                            <k-input
                              type="text"
                              placeholder="Default value"
                              v-model="evData.google.backRowField1Value"
                            >
                            </k-input>
                          </div>
                        </cardBody>
                      </card>

                      <card class="width-437 mb-4">
                        <cardBody class="p-0">
                          <div
                            class="py-12 px-16 border-bottom-normal-1 d-flex justify-content-between"
                          >
                            <cardTitle class="font-16 font-w-400 m-0"
                              >Second Field</cardTitle
                            >
                            <!-- <kbutton
                              class="p-0 btn-hover-none"
                              :icon="'delete'"
                              :fill-mode="'flat'"
                            ></kbutton> -->
                          </div>
                          <div class="p-24 pb-2">
                            <label for="">Field Label</label>
                            <k-input
                              type="text"
                              placeholder="Field Label"
                              v-model="evData.google.backRowField2Label"
                            >
                            </k-input>
                            <label for="">Default value</label>
                            <k-input
                              type="text"
                              placeholder="Default value"
                              v-model="evData.google.backRowField2Value"
                            >
                            </k-input>
                          </div>
                        </cardBody>
                      </card>

                      <card class="width-437 mb-4">
                        <cardBody class="p-0">
                          <div
                            class="py-12 px-16 border-bottom-normal-1 d-flex justify-content-between"
                          >
                            <cardTitle class="font-16 font-w-400 m-0"
                              >Third Field</cardTitle
                            >
                            <!-- <kbutton
                              class="p-0 btn-hover-none"
                              :icon="'delete'"
                              :fill-mode="'flat'"
                            ></kbutton> -->
                          </div>
                          <div class="p-24 pb-2">
                            <label for="">Field Label</label>
                            <k-input
                              type="text"
                              placeholder="Field Label"
                              v-model="evData.google.backRowField3Label"
                            >
                            </k-input>
                            <label for="">Default value</label>
                            <k-input
                              type="text"
                              placeholder="Default value"
                              v-model="evData.google.backRowField3Value"
                            >
                            </k-input>
                          </div>
                        </cardBody>
                      </card>
                      <!-- <kbutton
                        class="p-0 mt-26 btn-hover-none color-blue"
                        :fill-mode="'flat'"
                        >+ ADD NEW FIELDS</kbutton
                      > -->
                    </div>
                  </tabstripTab>

                  <tabstripTab :title-render="appleLeftSidebarBack">
                    <template v-slot:appleLeftSidebarBack="{ props }">
                      <div><span class="cb-expire"></span> Expiry Date</div>
                    </template>
                    <div>
                      <h4 class="font-19">Expiry Date</h4>
                      <p class="text-color-2 mb-24">
                        Select an expiry date for digital pass.
                      </p>
                      <card class="width-437 mt-4">
                        <cardBody class="p-0">
                          <div class="p-24 pb-2">
                            <label for="">Expiry Date</label>
                            <datetimepicker
                              :min="new Date()"
                              :default-value="currDate"
                              :format="'dd MMM yyyy hh:mm:ss a'"
                              :value="endDatePickerValue"
                              @change="updateEndDatepicker"
                            />
                          </div>
                        </cardBody>
                      </card>
                    </div>
                  </tabstripTab>
                </tabstrip>
              </div>
            </div>

            <div class="col-xl-5 col-lg-12 p-0">
              <div class="ticket-preview-wrap">
                <div class="mb-3 d-flex justify-content-center">
                  <kbutton
                    :class="'btn-white'"
                    :icon="'arrow-right'"
                    :theme-color="'null'"
                    @click="openTestEmailModal"
                  >
                    Send Test Email</kbutton
                  >
                </div>

                <div class="pass-preview-main">
                  <div
                    :style="{ background: evData.google.backgroundColor }"
                    class="pass-preview"
                  >
                    <div class="pass-header">
                      <div class="d-flex align-items-center">
                        <div class="logo">
                          <img
                            v-if="this.evData.google.logo"
                            :src="this.evData.google.logo"
                            alt="Logo"
                          />
                        </div>
                        <div class="">
                          <div class="title font-12 mb-0 wrap">
                            <div
                              :style="{ color: evData.google.googleTextColor }"
                              class="pass-placeholder h13 l-height-21 font-16 h-auto overflow-visible"
                            >
                              {{ evData.google.header }}
                            </div>
                          </div>
                          <!-- <div class="details">
                            <div
                              :style="{ color: evData.google.googleTextColor }"
                              class="pass-placeholder h13 l-height-21 font-16 h-auto white-space-wrap overflow-visible"
                            >
                              {{ evData.google.subHeader }}
                            </div>
                          </div> -->
                        </div>
                      </div>
                    </div>

                    <div class="pass-date-time pt-0">
                      <div :class="cssClassFunc">
                        <div
                          class="px-16 w-50-p"
                          v-if="evData.google.secondRowField1Value"
                        >
                          <label
                            :style="{ color: evData.google.googleTextColor }"
                            >{{ evData.google.secondRowField1Label }}</label
                          >
                          <div
                            :style="{ color: evData.google.googleTextColor }"
                            class="pass-placeholder font-16"
                          >
                            {{ evData.google.secondRowField1Value }}
                          </div>
                        </div>
                        <div
                          v-if="evData.google.secondRowField2Value"
                          class="px-16 text-right w-50-p"
                        >
                          <label
                            :style="{ color: evData.google.googleTextColor }"
                            >{{ evData.google.secondRowField2Label }}</label
                          >
                          <div
                            :style="{ color: evData.google.googleTextColor }"
                            class="pass-placeholder font-16"
                          >
                            {{ evData.google.secondRowField2Value }}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      v-if="evData.barcodeType != 'none'"
                      class="pass-barcode"
                    >
                      <div
                        v-if="evData.barcodeType === 'qrcode'"
                        class="barcode-box"
                      >
                        <img src="../../../../../assets/images/bar-code.png" />
                      </div>
                      <div
                        v-if="evData.barcodeType === 'pdf417'"
                        class="barcode-box"
                      >
                        <img
                          src="../../../../../assets/images/pdf417-for-pass.png"
                        />
                      </div>
                      <div
                        :style="{ color: evData.google.googleTextColor }"
                        class="pass-placeholder h14"
                      >
                        {{ evData.barcodeAltText }}
                      </div>
                    </div>
                    <div
                      v-if="evData.google.thumbnail"
                      class="pass-thumb pb-2 px-2"
                    >
                      <img
                        v-if="evData.google.thumbnail"
                        :src="evData.google.thumbnail"
                        alt="Thumbnail"
                      />
                    </div>
                  </div>

                  <div class="pass-details box-shadow-none px-3 py-3">
                    <div v-if="evData.google.backRowField1Label" class="">
                      <label>{{ evData.google.backRowField1Label }}</label>
                      <div class="pass-details-content">
                        {{ evData.google.backRowField1Value }}
                      </div>
                    </div>

                    <div v-if="evData.google.backRowField2Label" class="">
                      <label>{{ evData.google.backRowField2Label }}</label>
                      <div class="pass-details-content">
                        {{ evData.google.backRowField2Value }}
                      </div>
                    </div>

                    <div v-if="evData.google.backRowField3Label" class="">
                      <label>{{ evData.google.backRowField3Label }}</label>
                      <div class="pass-details-content">
                        {{ evData.google.backRowField3Value }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </tabstripTab>

        <tabstripTab :title="'Apple Pass'">
          <!-- <ApplePass /> -->
          <div class="row m-0">
            <div class="col-xl-7 col-lg-12 p-0">
              <div class="pass-left-content">
                <tabstrip
                  :selected="appleSideBarSelected"
                  @select="appleSideBarOnSelect"
                  :tabPosition="'left'"
                  :class="'left-side-tab'"
                >
                  <tabstripTab :title-render="appleLeftSidebarLogoArea">
                    <template v-slot:appleLeftSidebarLogoArea="{ props }">
                      <div><span class="cb-logo-area"></span> Logo Area</div>
                    </template>
                    <!-- <GooglePass /> -->
                    <div>
                      <h4 class="font-19">Logo Area</h4>
                      <p class="color-light2 font-14 mb-32">
                        Define your {{ isClub() }}'s logo and Organization name
                        to display on pass.
                      </p>
                      <h4 class="font-14 font-w-700">Logo Image</h4>
                      <p class="color-light2 font-14">
                        Logos are mandatory for wallet pass. The recommended
                        dimensions are 150 pixels by 150 pixels.
                      </p>

                      <div class="file-uploader-custom">
                        <div class="uplodade-img-box">
                          <kbutton
                            v-if="evData.apple.logo"
                            :icon="'close'"
                            @click="removeAppleImages('logo')"
                            :size="'small'"
                          ></kbutton>
                          <img
                            v-if="evData.apple.logo"
                            :src="evData.apple.logo"
                          />
                          <img
                            v-else
                            src="../../../../../assets/images/login-bg.png"
                          />
                        </div>
                        <input
                          type="file"
                          title="Select a file"
                          @change="handleAppleLogoUpload"
                          accept="image/png, image/jpeg"
                        />
                      </div>

                      <div>
                        <label class="mt-4" for="">Logo Text*</label>
                        <k-input
                          type="text"
                          placeholder=""
                          v-model="evData.apple.logoText"
                        ></k-input>
                      </div>
                      <div>
                        <label for="">Description*</label>
                        <k-input
                          type="text"
                          placeholder=""
                          v-model="evData.apple.description"
                        ></k-input>
                      </div>
                      <div>
                        <label for="">Organization Name*</label>
                        <k-input
                          type="text"
                          placeholder=""
                          v-model="evData.apple.organizationName"
                        ></k-input>
                      </div>
                    </div>
                  </tabstripTab>

                  <tabstripTab :title-render="appleLeftSidebarColor">
                    <template v-slot:appleLeftSidebarColor="{ props }">
                      <div><span class="cb-colors"></span> Colors</div>
                    </template>
                    <!-- <ApplePass /> -->
                    <div>
                      <h4 class="font-19">Colors</h4>
                      <p class="color-light2 font-14">
                        Choose attractive colour combination for your digital
                        pass.
                      </p>
                    </div>
                    <h4 class="font-16 font-w-400 mb-8 mt-32">
                      Background Color
                    </h4>
                    <div class="color-picker-wrapper">
                      <span
                        :style="{ color: evData.apple.appleBgTextColor }"
                        class="color-name font-16"
                        v-text="evData.apple.backgroundColor"
                      ></span>
                      <kendo-colorpicker
                        :value="evData.apple.backgroundColor"
                        @change="OnAppleChangesColorPicker($event, 'bgColor')"
                        >ColorPicker</kendo-colorpicker
                      >
                    </div>
                    <h4 class="font-16 font-w-400 mb-8 mt-32">Label Color</h4>
                    <div class="color-picker-wrapper">
                      <span
                        :style="{ color: evData.apple.appleLabelTextColor }"
                        class="color-name font-16"
                        v-text="evData.apple.labelColor"
                      ></span>
                      <kendo-colorpicker
                        :value="evData.apple.labelColor"
                        @change="OnAppleChangesColorPicker($event, 'label')"
                        >ColorPicker</kendo-colorpicker
                      >
                    </div>
                    <h4 class="font-16 font-w-400 mb-8 mt-32">Text Color</h4>
                    <div class="color-picker-wrapper">
                      <span
                        :style="{ color: evData.apple.appleTextColor }"
                        class="color-name font-16"
                        v-text="evData.apple.textColor"
                      ></span>
                      <kendo-colorpicker
                        :value="evData.apple.textColor"
                        @change="OnAppleChangesColorPicker($event, 'text')"
                        >ColorPicker</kendo-colorpicker
                      >
                    </div>
                  </tabstripTab>

                  <tabstripTab :title-render="appleLeftSidebarImage">
                    <template v-slot:appleLeftSidebarImage="{ props }">
                      <div><span class="cb-logo-area"></span> Images</div>
                    </template>
                    <!-- <BarCodeSettings /> -->
                    <div>
                      <h4 class="font-19">Images</h4>
                      <p class="color-light2 font-14 mb-28">
                        Add your {{ isClub() }}'s logo to display on the digital
                        pass.
                      </p>
                      <radiobutton
                        :size="'large'"
                        :name="'group1'"
                        :value="'first'"
                        :checked="applePassSelectedValue === 'first'"
                        @change="applePassHandleChange"
                        :label="'Thumbnail Image'"
                      />
                      <p class="mt-12 mb-12 color-light2 font-14 ps-4">
                        Add a hero image to give customers an idea of what
                        they'd be purchasing. This Recommended dimensions of
                        1125 by 432 pixels.
                      </p>

                      <div
                        class="file-uploader-custom ms-4 mb-4"
                        v-if="applePassSelectedValue === 'first'"
                      >
                        <div class="uplodade-img-box">
                          <kbutton
                            v-if="evData.apple.thumbnail"
                            :icon="'close'"
                            @click="removeAppleImages('thumbnail')"
                            :size="'small'"
                          ></kbutton>
                          <img
                            v-if="evData.apple.thumbnail"
                            :src="evData.apple.thumbnail"
                          />
                          <img
                            v-else
                            src="../../../../../assets/images/login-bg.png"
                          />
                        </div>
                        <input
                          v-if="applePassSelectedValue === 'first'"
                          title="Select a file"
                          type="file"
                          @change="handleAppleThumbnailUpload"
                          accept="image/png, image/jpeg"
                        />
                      </div>

                      <radiobutton
                        :size="'large'"
                        :name="'group1'"
                        :value="'second'"
                        :checked="applePassSelectedValue === 'second'"
                        @change="applePassHandleChange"
                        :label="'Strip Image'"
                      />
                      <p class="mt-12 mb-12 color-light2 font-14 ps-4">
                        Add a hero image to give customers an idea of what
                        they'd be purchasing. This Recommended dimensions of
                        1125 by 432 pixels.
                      </p>

                      <div
                        class="file-uploader-custom ms-4 mb-4"
                        v-if="applePassSelectedValue === 'second'"
                      >
                        <div class="uplodade-img-box">
                          <kbutton
                            v-if="evData.apple.strip"
                            :icon="'close'"
                            @click="removeAppleImages('strip')"
                            :size="'small'"
                          ></kbutton>
                          <img
                            v-if="evData.apple.strip"
                            :src="evData.apple.strip"
                          />
                          <img
                            v-else
                            src="../../../../../assets/images/login-bg.png"
                          />
                        </div>

                        <input
                          v-if="applePassSelectedValue === 'second'"
                          title="Select a file"
                          type="file"
                          @change="handleAppleStripUpload"
                          accept="image/png, image/jpeg"
                        />
                      </div>

                      <radiobutton
                        :name="'group1'"
                        :value="'third'"
                        :checked="applePassSelectedValue === 'third'"
                        @change="applePassHandleChange"
                        :label="'No Image'"
                      />
                    </div>
                  </tabstripTab>
                  <tabstripTab :title-render="appleLeftSidebarHeaderField">
                    <template v-slot:appleLeftSidebarHeaderField="{ props }">
                      <div><span class="cb-top-row"></span> Header Field</div>
                    </template>
                    <!-- <BarCodeSettings /> -->
                    <div>
                      <h4 class="font-19">Header Field</h4>
                      <p class="text-color-2 mb-24">
                        Select header information here.
                      </p>
                      <card class="width-437 mb-4">
                        <cardBody class="p-0">
                          <div
                            class="py-12 px-16 border-bottom-normal-1 d-flex justify-content-between"
                          >
                            <cardTitle class="font-16 font-w-400 m-0"
                              >Field</cardTitle
                            >
                            <!-- <kbutton
                              class="p-0 btn-hover-none"
                              :icon="'delete'"
                              :fill-mode="'flat'"
                            ></kbutton> -->
                          </div>
                          <div class="p-24 pb-2">
                            <label for="">Field Label</label>
                            <k-input
                              type="text"
                              placeholder="Field Label"
                              v-model="evData.apple.headerField1Label"
                            >
                            </k-input>
                            <label for="">Default value</label>
                            <k-input
                              type="text"
                              placeholder="Default value"
                              v-model="evData.apple.headerField1Value"
                            >
                            </k-input>
                          </div>
                        </cardBody>
                      </card>
                      <!-- <kbutton
                        class="p-0 mt-26 btn-hover-none color-blue"
                        :fill-mode="'flat'"
                        >+ ADD NEW FIELDS</kbutton
                      > -->
                    </div>
                  </tabstripTab>
                  <tabstripTab :title-render="appleLeftSidebarPrimaryField">
                    <template v-slot:appleLeftSidebarPrimaryField="{ props }">
                      <div>
                        <span class="cb-second-row"></span> Primary Field
                      </div>
                    </template>
                    <!-- <BarCodeSettings /> -->
                    <div>
                      <h4 class="font-19">Primary Field</h4>
                      <p class="text-color-2 mb-24">
                        Select primary informations here.
                      </p>
                      <card class="width-437 mb-4">
                        <cardBody class="p-0">
                          <div
                            class="py-12 px-16 border-bottom-normal-1 d-flex justify-content-between"
                          >
                            <cardTitle class="font-16 font-w-400 m-0"
                              >Field</cardTitle
                            >
                            <!-- <kbutton
                              class="p-0 btn-hover-none"
                              :icon="'delete'"
                              :fill-mode="'flat'"
                            ></kbutton> -->
                          </div>
                          <div class="p-24 pb-2">
                            <label for="">Field Label</label>
                            <k-input
                              type="text"
                              placeholder="Field Label"
                              v-model="evData.apple.primaryField1Label"
                            >
                            </k-input>
                            <label for="">Default value</label>
                            <k-input
                              type="text"
                              placeholder="Default value"
                              v-model="evData.apple.primaryField1Value"
                            >
                            </k-input>
                          </div>
                        </cardBody>
                      </card>
                      <!-- <kbutton
                        class="p-0 mt-26 btn-hover-none color-blue"
                        :fill-mode="'flat'"
                        >+ ADD NEW FIELDS</kbutton
                      > -->
                    </div>
                  </tabstripTab>
                  <tabstripTab :title-render="appleLeftSidebarSecondaryField">
                    <template v-slot:appleLeftSidebarSecondaryField="{ props }">
                      <div>
                        <span class="cb-third-row"></span> Secondary Field
                      </div>
                    </template>
                    <!-- <BarCodeSettings /> -->
                    <div>
                      <h4 class="font-19">Secondary Field</h4>
                      <p class="text-color-2 mb-24">
                        Select secondary informations here.
                      </p>
                      <card class="width-437 mb-4">
                        <cardBody class="p-0">
                          <div
                            class="py-12 px-16 border-bottom-normal-1 d-flex justify-content-between"
                          >
                            <cardTitle class="font-16 font-w-400 m-0"
                              >First Field</cardTitle
                            >
                            <!-- <kbutton
                              class="p-0 btn-hover-none"
                              :icon="'delete'"
                              :fill-mode="'flat'"
                            ></kbutton> -->
                          </div>
                          <div class="p-24 pb-2">
                            <label for="">Field Label</label>
                            <k-input
                              type="text"
                              placeholder="Field Label"
                              v-model="evData.apple.secondaryField1Label"
                            >
                            </k-input>
                            <label for="">Default value</label>
                            <k-input
                              type="text"
                              placeholder="Default value"
                              v-model="evData.apple.secondaryField1Value"
                            >
                            </k-input>
                          </div>
                        </cardBody>
                      </card>

                      <card class="width-437 mb-4">
                        <cardBody class="p-0">
                          <div
                            class="py-12 px-16 border-bottom-normal-1 d-flex justify-content-between"
                          >
                            <cardTitle class="font-16 font-w-400 m-0"
                              >Second Field</cardTitle
                            >
                            <!-- <kbutton
                              class="p-0 btn-hover-none"
                              :icon="'delete'"
                              :fill-mode="'flat'"
                            ></kbutton> -->
                          </div>
                          <div class="p-24 pb-2">
                            <label for="">Field Label</label>
                            <k-input
                              type="text"
                              placeholder="Field Label"
                              v-model="evData.apple.secondaryField2Label"
                            >
                            </k-input>
                            <label for="">Default value</label>
                            <k-input
                              type="text"
                              placeholder="Default value"
                              v-model="evData.apple.secondaryField2Value"
                            >
                            </k-input>
                          </div>
                        </cardBody>
                      </card>

                      <card class="width-437 mb-4">
                        <cardBody class="p-0">
                          <div
                            class="py-12 px-16 border-bottom-normal-1 d-flex justify-content-between"
                          >
                            <cardTitle class="font-16 font-w-400 m-0"
                              >Third Field</cardTitle
                            >
                            <!-- <kbutton
                              class="p-0 btn-hover-none"
                              :icon="'delete'"
                              :fill-mode="'flat'"
                            ></kbutton> -->
                          </div>
                          <div class="p-24 pb-2">
                            <label for="">Field Label</label>
                            <k-input
                              type="text"
                              placeholder="Field Label"
                              v-model="evData.apple.secondaryField3Label"
                            >
                            </k-input>
                            <label for="">Default value</label>
                            <k-input
                              type="text"
                              placeholder="Default value"
                              v-model="evData.apple.secondaryField3Value"
                            >
                            </k-input>
                          </div>
                        </cardBody>
                      </card>
                      <!-- <kbutton
                        class="p-0 mt-26 btn-hover-none color-blue"
                        :fill-mode="'flat'"
                        >+ ADD NEW FIELDS</kbutton
                      > -->
                    </div>
                  </tabstripTab>
                  <tabstripTab :title-render="appleLeftSidebarAuxillaryField">
                    <template v-slot:appleLeftSidebarAuxillaryField="{ props }">
                      <div>
                        <span class="cb-details"></span> Auxiliary Field
                      </div>
                    </template>
                    <!-- <BarCodeSettings /> -->
                    <div>
                      <h4 class="font-19">Auxiliary Field</h4>
                      <p class="text-color-2 mb-24">
                        Select auxiliary informations here.
                      </p>
                      <card class="width-437 mb-4">
                        <cardBody class="p-0">
                          <div
                            class="py-12 px-16 border-bottom-normal-1 d-flex justify-content-between"
                          >
                            <cardTitle class="font-16 font-w-400 m-0"
                              >First Field</cardTitle
                            >
                            <!-- <kbutton
                              class="p-0 btn-hover-none"
                              :icon="'delete'"
                              :fill-mode="'flat'"
                            ></kbutton> -->
                          </div>
                          <div class="p-24 pb-2">
                            <label for="">Field Label</label>
                            <k-input
                              type="text"
                              placeholder="Field Label"
                              v-model="evData.apple.auxiliaryField1Label"
                            >
                            </k-input>
                            <label for="">Default value</label>
                            <k-input
                              type="text"
                              placeholder="Default value"
                              v-model="evData.apple.auxiliaryField1Value"
                            >
                            </k-input>
                          </div>
                        </cardBody>
                      </card>

                      <card class="width-437 mb-4">
                        <cardBody class="p-0">
                          <div
                            class="py-12 px-16 border-bottom-normal-1 d-flex justify-content-between"
                          >
                            <cardTitle class="font-16 font-w-400 m-0"
                              >Second Field</cardTitle
                            >
                            <!-- <kbutton
                              class="p-0 btn-hover-none"
                              :icon="'delete'"
                              :fill-mode="'flat'"
                            ></kbutton> -->
                          </div>
                          <div class="p-24 pb-2">
                            <label for="">Field Label</label>
                            <k-input
                              type="text"
                              placeholder="Field Label"
                              v-model="evData.apple.auxiliaryField2Label"
                            >
                            </k-input>
                            <label for="">Default value</label>
                            <k-input
                              type="text"
                              placeholder="Default value"
                              v-model="evData.apple.auxiliaryField2Value"
                            >
                            </k-input>
                          </div>
                        </cardBody>
                      </card>

                      <card class="width-437 mb-4">
                        <cardBody class="p-0">
                          <div
                            class="py-12 px-16 border-bottom-normal-1 d-flex justify-content-between"
                          >
                            <cardTitle class="font-16 font-w-400 m-0"
                              >Third Field</cardTitle
                            >
                            <!-- <kbutton
                              class="p-0 btn-hover-none"
                              :icon="'delete'"
                              :fill-mode="'flat'"
                            ></kbutton> -->
                          </div>
                          <div class="p-24 pb-2">
                            <label for="">Field Label</label>
                            <k-input
                              type="text"
                              placeholder="Field Label"
                              v-model="evData.apple.auxiliaryField3Label"
                            >
                            </k-input>
                            <label for="">Default value</label>
                            <k-input
                              type="text"
                              placeholder="Default value"
                              v-model="evData.apple.auxiliaryField3Value"
                            >
                            </k-input>
                          </div>
                        </cardBody>
                      </card>
                      <!-- <kbutton
                        class="p-0 mt-26 btn-hover-none color-blue"
                        :fill-mode="'flat'"
                        >+ ADD NEW FIELDS</kbutton
                      > -->
                    </div>
                  </tabstripTab>
                  <tabstripTab :title-render="appleLeftSidebarBack">
                    <template v-slot:appleLeftSidebarBack="{ props }">
                      <div><span class="cb-back-icon"></span> Back</div>
                    </template>
                    <!-- <BarCodeSettings /> -->
                    <div>
                      <h4 class="font-19">Back</h4>
                      <p class="text-color-2 mb-24">
                        Select card backside informations here.
                      </p>
                      <card class="width-437 mb-4">
                        <cardBody class="p-0">
                          <div
                            class="py-12 px-16 border-bottom-normal-1 d-flex justify-content-between"
                          >
                            <cardTitle class="font-16 font-w-400 m-0"
                              >First Field</cardTitle
                            >
                            <!-- <kbutton
                              class="p-0 btn-hover-none"
                              :icon="'delete'"
                              :fill-mode="'flat'"
                            ></kbutton> -->
                          </div>
                          <div class="p-24 pb-2">
                            <label for="">Field Label</label>
                            <k-input
                              type="text"
                              placeholder="Field Label"
                              v-model="evData.apple.backField1Label"
                            >
                            </k-input>
                            <label for="">Default value</label>
                            <k-input
                              type="text"
                              placeholder="Default value"
                              v-model="evData.apple.backField1Value"
                            >
                            </k-input>
                          </div>
                        </cardBody>
                      </card>

                      <card class="width-437 mb-4">
                        <cardBody class="p-0">
                          <div
                            class="py-12 px-16 border-bottom-normal-1 d-flex justify-content-between"
                          >
                            <cardTitle class="font-16 font-w-400 m-0"
                              >Second Field</cardTitle
                            >
                            <!-- <kbutton
                              class="p-0 btn-hover-none"
                              :icon="'delete'"
                              :fill-mode="'flat'"
                            ></kbutton> -->
                          </div>
                          <div class="p-24 pb-2">
                            <label for="">Field Label</label>
                            <k-input
                              type="text"
                              placeholder="Field Label"
                              v-model="evData.apple.backField2Label"
                            >
                            </k-input>
                            <label for="">Default value</label>
                            <k-input
                              type="text"
                              placeholder="Default value"
                              v-model="evData.apple.backField2Value"
                            >
                            </k-input>
                          </div>
                        </cardBody>
                      </card>

                      <card class="width-437 mb-4">
                        <cardBody class="p-0">
                          <div
                            class="py-12 px-16 border-bottom-normal-1 d-flex justify-content-between"
                          >
                            <cardTitle class="font-16 font-w-400 m-0"
                              >Third Field</cardTitle
                            >
                            <!-- <kbutton
                              class="p-0 btn-hover-none"
                              :icon="'delete'"
                              :fill-mode="'flat'"
                            ></kbutton> -->
                          </div>
                          <div class="p-24 pb-2">
                            <label for="">Field Label</label>
                            <k-input
                              type="text"
                              placeholder="Field Label"
                              v-model="evData.apple.backField3Label"
                            >
                            </k-input>
                            <label for="">Default value</label>
                            <k-input
                              type="text"
                              placeholder="Default value"
                              v-model="evData.apple.backField3Value"
                            >
                            </k-input>
                          </div>
                        </cardBody>
                      </card>
                      <!-- <kbutton
                        class="p-0 mt-26 btn-hover-none color-blue"
                        :fill-mode="'flat'"
                        >+ ADD NEW FIELDS</kbutton
                      > -->
                    </div>
                  </tabstripTab>

                  <tabstripTab :title-render="appleLeftSidebarBack">
                    <template v-slot:appleLeftSidebarBack="{ props }">
                      <div><span class="cb-expire"></span> Expires</div>
                    </template>
                    <div>
                      <h4 class="font-19">Expires</h4>
                      <p class="text-color-2 mb-24">
                        Select an expiry date for digital pass.
                      </p>
                      <card class="width-437 mt-4">
                        <cardBody class="p-0">
                          <div class="p-24 pb-2">
                            <label for="">Expire Date</label>
                            <datetimepicker
                              :min="new Date()"
                              :default-value="currDate"
                              :format="'dd MMM yyyy hh:mm:ss a'"
                              :value="endDatePickerValueApple"
                              @change="updateEndDatepickerApple"
                            />
                          </div>
                        </cardBody>
                      </card>
                    </div>
                  </tabstripTab>
                </tabstrip>
              </div>
            </div>

            <div class="col-xl-5 col-lg-12 p-0">
              <div class="ticket-preview-wrap apple-pass">
                <div class="mb-3 d-flex justify-content-center">
                  <kbutton
                    :class="'btn-white'"
                    :icon="'arrow-right'"
                    :theme-color="'null'"
                    @click="openTestEmailModal"
                  >
                    Send Test Email</kbutton
                  >
                </div>

                <tabstrip
                  class="tabstrip-right have-tooltip"
                  :selected="applePreviewSelected"
                  @select="applePreviewOnSelect"
                  :tabPosition="'right'"
                >
                  <tabstripTab :title-render="appleFrontTitleRender">
                    <template v-slot:appleFrontTitleRender="{ props }">
                      <div>
                        <div class="custom-tab-tooltip">Front</div>
                        <span class="cb-layout-front"></span>
                      </div>
                    </template>
                    <div class="pass-preview-main no-image">
                      <div
                        :style="{ background: evData.apple.backgroundColor }"
                        class="pass-preview"
                      >
                        <div class="pass-header">
                          <div class="d-flex align-items-center">
                            <div v-if="evData.apple.logo" class="logo">
                              <img :src="evData.apple.logo" alt="Logo" />
                            </div>
                            <div class="title font-16">
                              <div
                                :style="{ color: evData.apple.textColor }"
                                class="pass-placeholder h20"
                              >
                                {{ evData.apple.logoText }}
                              </div>
                            </div>

                            <div class="title ps-3">
                              <div
                                :style="{ color: evData.apple.labelColor }"
                                class="pass-placeholder h20 text-right font-11 text-uppercase h-auto l-height-11"
                              >
                                {{ evData.apple.headerField1Label }}
                              </div>
                              <div
                                v-if="evData.apple.headerField1Label"
                                class="details mt-0 text-right l-height-19"
                              >
                                <div
                                  :style="{ color: evData.apple.textColor }"
                                  class="pass-placeholder h13 font-11 h-auto"
                                >
                                  {{ evData.apple.headerField1Value }}
                                </div>
                              </div>
                            </div>
                          </div>

                          <!--<div-->
                          <!--v-if="evData.apple.headerField1Label"-->
                          <!--class="details"-->
                          <!--&gt;-->
                          <!--<div-->
                          <!--:style="{ color: evData.apple.textColor }"-->
                          <!--class="pass-placeholder h13"-->
                          <!--&gt;-->
                          <!--{{ evData.apple.headerField1Value }}-->
                          <!--</div>-->
                          <!--</div>-->
                        </div>

                        <div :class="appleThumbnailContainerClass" class="pt-1">
                          <div class="row m-0">
                            <div class="col-8 p-0">
                              <div class="row m-0">
                                <div
                                  v-if="
                                    evData.apple.primaryField1Label &&
                                    evData.apple.primaryField1Value
                                  "
                                  class="col-12"
                                >
                                  <label
                                    class="font-11 l-height-11 text-uppercase h-auto"
                                    :style="{ color: evData.apple.labelColor }"
                                  >
                                    {{ evData.apple.primaryField1Label }}
                                  </label>
                                  <div
                                    :style="{ color: evData.apple.textColor }"
                                    class="pass-placeholder font-16"
                                  >
                                    {{ evData.apple.primaryField1Value }}
                                  </div>
                                </div>

                                <div class="col-12">
                                  <div class="dynamic-row">
                                    <div
                                      v-if="evData.apple.secondaryField1Label"
                                      class="pe-1 pt-3"
                                    >
                                      <label
                                        :style="{
                                          color: evData.apple.labelColor,
                                        }"
                                      >
                                        {{ evData.apple.secondaryField1Label }}
                                      </label>
                                      <div
                                        :style="{
                                          color: evData.apple.textColor,
                                        }"
                                        class="pass-placeholder font-w-500 text-uppercase"
                                      >
                                        {{ evData.apple.secondaryField1Value }}
                                      </div>
                                    </div>

                                    <div
                                      v-if="evData.apple.secondaryField2Label"
                                      class="pe-1 pt-3"
                                    >
                                      <label
                                        :style="{
                                          color: evData.apple.labelColor,
                                        }"
                                      >
                                        {{ evData.apple.secondaryField2Label }}
                                      </label>
                                      <div
                                        :style="{
                                          color: evData.apple.textColor,
                                        }"
                                        class="pass-placeholder font-w-500 text-uppercase"
                                      >
                                        {{ evData.apple.secondaryField2Value }}
                                      </div>
                                    </div>

                                    <div
                                      v-if="evData.apple.secondaryField3Label"
                                      class="pt-3"
                                    >
                                      <label
                                        :style="{
                                          color: evData.apple.labelColor,
                                        }"
                                      >
                                        {{ evData.apple.secondaryField3Label }}
                                      </label>
                                      <div
                                        :style="{
                                          color: evData.apple.textColor,
                                        }"
                                        class="pass-placeholder font-w-500 text-uppercase"
                                      >
                                        {{ evData.apple.secondaryField3Value }}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div class="col-4 px-0">
                              <div
                                style="display: none"
                                v-if="evData.apple.primaryField1Label"
                                class="primary-field-absolute"
                              >
                                <label
                                  v-if="!evData.apple.strip"
                                  :style="{ color: evData.apple.labelColor }"
                                >
                                  {{ evData.apple.primaryField1Label }}
                                </label>
                                <div
                                  :style="{ color: evData.apple.textColor }"
                                  class="pass-placeholder font-28"
                                >
                                  {{ evData.apple.primaryField1Value }}
                                </div>
                              </div>

                              <div
                                v-if="this.evData.apple.thumbnail"
                                class="pass-thumb px-16"
                              >
                                <img :src="this.evData.apple.thumbnail" />
                              </div>
                              <div
                                v-if="this.evData.apple.strip"
                                class="pass-strip text-center"
                              >
                                <img :src="this.evData.apple.strip" />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="pass-cat-sec-row">
                          <div class="row m-0">
                            <div class="col-12">
                              <div class="dynamic-row">
                                <div
                                  class="pe-2"
                                  v-if="evData.apple.auxiliaryField1Label"
                                >
                                  <label
                                    class="mb-0"
                                    :style="{ color: evData.apple.labelColor }"
                                    >{{
                                      evData.apple.auxiliaryField1Label
                                    }}</label
                                  >
                                  <div
                                    :style="{ color: evData.apple.textColor }"
                                    class="pass-placeholder font-w-500 text-uppercase no-ellipsis"
                                  >
                                    {{ evData.apple.auxiliaryField1Value }}
                                  </div>
                                </div>

                                <div
                                  class="pe-2"
                                  v-if="evData.apple.auxiliaryField2Label"
                                >
                                  <label
                                    class="mb-0"
                                    :style="{ color: evData.apple.labelColor }"
                                    >{{
                                      evData.apple.auxiliaryField2Label
                                    }}</label
                                  >
                                  <div
                                    :style="{ color: evData.apple.textColor }"
                                    class="pass-placeholder font-w-500 text-uppercase no-ellipsis"
                                  >
                                    {{ evData.apple.auxiliaryField2Value }}
                                  </div>
                                </div>

                                <div v-if="evData.apple.auxiliaryField3Label">
                                  <label
                                    class="mb-0"
                                    :style="{ color: evData.apple.labelColor }"
                                    >{{
                                      evData.apple.auxiliaryField3Label
                                    }}</label
                                  >
                                  <div
                                    :style="{ color: evData.apple.textColor }"
                                    class="pass-placeholder font-w-500 text-uppercase no-ellipsis"
                                  >
                                    {{ evData.apple.auxiliaryField3Value }}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          v-if="evData.barcodeType != 'none'"
                          class="pass-barcode"
                        >
                          <div
                            v-if="evData.barcodeType === 'qrcode'"
                            class="barcode-box"
                          >
                            <img
                              src="../../../../../assets/images/bar-code.png"
                            />
                          </div>
                          <div
                            v-if="evData.barcodeType === 'pdf417'"
                            class="barcode-box"
                          >
                            <img
                              src="../../../../../assets/images/pdf417-for-pass.png"
                            />
                          </div>
                          <div
                            :style="{ color: evData.apple.textColor }"
                            class="pass-placeholder h14"
                          >
                            {{ evData.barcodeAltText }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </tabstripTab>

                  <tabstripTab
                    class="preview-back"
                    :title-render="appleBackTitleRender"
                  >
                    <template v-slot:appleBackTitleRender="{ props }">
                      <div>
                        <div class="custom-tab-tooltip show-end">Back</div>
                        <span class="k-icon k-i-layout-side-by-side"></span>
                      </div>
                    </template>
                    <div class="pass-preview-main">
                      <div class="pass-preview">
                        <div class="pass-details">
                          <div>
                            <label class="text-transform-none">{{
                              evData.apple.backField1Label
                            }}</label>
                            <div class="pass-details-content">
                              {{ evData.apple.backField1Value }}
                            </div>
                          </div>

                          <div>
                            <label class="text-transform-none">{{
                              evData.apple.backField2Label
                            }}</label>
                            <div class="pass-details-content">
                              {{ evData.apple.backField2Value }}
                            </div>
                          </div>

                          <div
                            v-if="
                              evData.apple.backField3Label ||
                              evData.apple.backField3Value
                            "
                          >
                            <label class="text-transform-none">{{
                              evData.apple.backField3Label
                            }}</label>
                            <div class="pass-details-content">
                              {{ evData.apple.backField3Value }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </tabstripTab>
                </tabstrip>
              </div>
            </div>
          </div>
        </tabstripTab>

        <tabstripTab :title="'Barcode Settings'">
          <!-- <BarCodeSettings /> -->
          <div class="row m-0">
            <div class="col-lg-7 col-md-12 p-0">
              <div class="pass-left-content">
                <h4 class="font-19">Barcode Type</h4>
                <p class="text-color-2 mb-24">
                  Select the Barcode types for your passes here.
                </p>

                <div class="d-flex flex-wrap mb-32">
                  <div
                    class="d-flex flex-column text-center mr-32 mr-15-sm mb-15-sm"
                  >
                    <avatar
                      class="avatar-lg-2 border-gray bg-white"
                      :type="'null'"
                      :size="'null'"
                      :border="true"
                      :fill-mode="'outline'"
                      :theme-color="success"
                    >
                      <img
                        src="../../../../../assets/images/barcode.png"
                        alt=""
                      />
                    </avatar>
                    <label for="" class="mb-0 mt-2">QR CODE</label>
                    <radiobutton
                      :size="'large'"
                      :name="'barcodeGroup'"
                      :value="'qrcode'"
                      :checked="evData.barcodeType === 'qrcode'"
                      @change="barcodeHandleChange"
                      :label="''"
                    />
                  </div>

                  <div
                    class="d-flex flex-column text-center mr-32 mr-15-sm mb-15-sm"
                  >
                    <avatar
                      class="avatar-lg-2 border-gray bg-white"
                      :type="'null'"
                      :size="'null'"
                      :border="true"
                      :fill-mode="'outline'"
                      :theme-color="success"
                    >
                      <img src="../../../../../assets/images/pdf.png" alt="" />
                    </avatar>
                    <label for="" class="mb-0 mt-2">PDF 417</label>
                    <radiobutton
                      :size="'large'"
                      :name="'barcodeGroup'"
                      :value="'pdf417'"
                      :checked="evData.barcodeType === 'pdf417'"
                      @change="barcodeHandleChange"
                      :label="''"
                    />
                  </div>

                  <div
                    class="d-flex flex-column text-center mr-32 mr-15-sm mb-15-sm"
                  >
                    <avatar
                      class="avatar-lg-2 border-gray bg-white"
                      :type="'null'"
                      :size="'null'"
                      :border="true"
                      :fill-mode="'outline'"
                      :theme-color="success"
                    >
                    </avatar>
                    <label for="" class="mb-0 mt-2">NONE</label>
                    <radiobutton
                      :size="'large'"
                      :name="'barcodeGroup'"
                      :value="'none'"
                      :checked="evData.barcodeType === 'none'"
                      @change="barcodeHandleChange"
                      :label="''"
                    />
                  </div>
                </div>

                <div v-if="evData.barcodeType != 'none'" class="row">
                  <div class="col-md-6">
                    <label for="">Barcode Content</label>
                    <k-input
                      type="text"
                      placeholder="Barcode Content"
                      v-model="evData.barcodeContent"
                    ></k-input>
                  </div>
                  <div class="col-md-6">
                    <label for="">Barcode alt Text</label>
                    <k-input
                      type="text"
                      placeholder="Barcode alt Text"
                      v-model="evData.barcodeAltText"
                    ></k-input>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </tabstripTab>
      </tabstrip>
    </div>
  </div>
</template>

<script>
import { HTTP } from "@/service";
import { TabStrip, TabStripTab } from "@progress/kendo-vue-layout";
import { Button } from "@progress/kendo-vue-buttons";
import { ColorPicker } from "@progress/kendo-inputs-vue-wrapper";
import { Upload } from "@progress/kendo-vue-upload";
import { Input } from "@progress/kendo-vue-inputs";
import { RadioButton } from "@progress/kendo-vue-inputs";
import { Checkbox } from "@progress/kendo-vue-inputs";
import { DatePicker } from "@progress/kendo-vue-dateinputs";
import { DateTimePicker } from "@progress/kendo-vue-dateinputs";
import AppModal from "../../../../common/AppModal/AppModal.vue";
import AppSnackBar from "../../../../common/AppSnackBar/AppSnackBar.vue";
import loader from "../../../../common/AppLoader/AppLoader.vue";
import logoPlaceholder from "../../../../../assets/images/logoPlaceholder";
import moment from "moment";
import { isPastDate } from "@/utils/helper";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  CardImage,
  CardActions,
  Avatar,
} from "@progress/kendo-vue-layout";
import { configuration } from "@/configurationProvider";
const emailRegex = new RegExp(/\S+@\S+\.\S+/);
export default {
  name: "MembershipCard",
  components: {
    tabstrip: TabStrip,
    tabstripTab: TabStripTab,
    kbutton: Button,
    "kendo-colorpicker": ColorPicker,
    upload: Upload,
    loader,
    "k-input": Input,
    radiobutton: RadioButton,
    card: Card,
    cardHeader: CardHeader,
    cardBody: CardBody,
    cardTitle: CardTitle,
    cardImage: CardImage,
    cardActions: CardActions,
    checkbox: Checkbox,
    datepicker: DatePicker,
    avatar: Avatar,
    datetimepicker: DateTimePicker,
    AppModal,
    AppSnackBar,
  },
  props: {
    id: {
      type: String,
    },
    template_id: {
      type: String,
      default: "0",
    },
    title: {
      type: String,
    },
  },
  data: function () {
    let currDate = new Date();
    currDate.setDate(currDate.getDate() + 7);
    return {
      isFromManageRecipients: this.$route.params.fromManageRecipients || false,
      fileUploadBox: true,
      loader: false,
      currDate: currDate,
      evData: {
        receiverEmail: "",
        google: {
          header: "My Organization",
          subHeader: "My Title",
          logo: logoPlaceholder,
          thumbnail: "",
          backgroundColor: "#6F42C1",
          secondRowField1Label: "Name",
          secondRowField1Value: "John Doe",
          secondRowField2Label: "ID",
          secondRowField2Value: "13101069",
          backRowField1Label: "Name",
          backRowField1Value: "John Doe",
          backRowField2Label: "ID",
          backRowField2Value: "13101069",
          backRowField3Label: "",
          backRowField3Value: "",
          expires: "",
          googleTextColor: "ffffff",
        },
        apple: {
          logo: logoPlaceholder,
          logoText: "My Organization",
          description: "My Description",
          organizationName: "My organization name",
          thumbnail: "",
          strip: "",
          backgroundColor: "#6F42C1",
          labelColor: "#ffffff",
          textColor: "#ffffff",
          headerField1Label: "",
          headerField1Value: "",
          primaryField1Label: "Name",
          primaryField1Value: "John Doe",
          secondaryField1Label: "Type",
          secondaryField1Value: "Premium",
          secondaryField2Label: "",
          secondaryField2Value: "",
          secondaryField3Label: "",
          secondaryField3Value: "",
          auxiliaryField1Label: "",
          auxiliaryField1Value: "",
          auxiliaryField2Label: "",
          auxiliaryField2Value: "",
          auxiliaryField3Label: "",
          auxiliaryField3Value: "",
          backField1Label: "Name",
          backField1Value: "John Doe",
          backField2Label: "ID",
          backField2Value: "13101069",
          backField3Label: "",
          backField3Value: "",
          expires: "",
          appleLabelTextColor: "#000000",
          appleTextColor: "#000000",
          appleBgTextColor: "#ffffff",
        },
        barcodeType: "qrcode",
        barcodeContent: "13101069",
        barcodeAltText: "13101069",
        templateId: "",
        templateName: "",
      },
      isNotify: false,
      notifyMessage: "Success",
      notifyType: "success",
      testEmailModalVisible: false,
      startDatePickerValue: new Date(),
      endDatePickerValue: new Date(),
      endDatePickerValueApple: new Date(),
      selected: 0,
      logoPreview: "",
      appleBackTitleRender: "appleBackTitleRender",
      appleFrontTitleRender: "appleFrontTitleRender",
      appleLeftSidebarLogoArea: "appleLeftSidebarLogoArea",
      appleLeftSidebarColor: "appleLeftSidebarColor",
      appleLeftSidebarImage: "appleLeftSidebarImage",
      appleLeftSidebarHeaderField: "appleLeftSidebarHeaderField",
      appleLeftSidebarPrimaryField: "appleLeftSidebarPrimaryField",
      appleLeftSidebarSecondaryField: "appleLeftSidebarSecondaryField",
      appleLeftSidebarAuxillaryField: "appleLeftSidebarAuxillaryField",
      appleLeftSidebarBack: "appleLeftSidebarBack",
      googleSideBarSelected: 0,
      color: "",
      googlePassSelectedValue: "first",

      appleSideBarSelected: 0,
      applePassSelectedValue: "first",
      applePreviewSelected: 0,
      appleThumbnailContainerClass: "pass-date-time",
      emailErrorMessage: "",
    };
  },
  computed: {
    cssClassFunc() {
      var counter = 0;
      if (
        this.evData.google.secondRowField1Label != "" &&
        this.evData.google.secondRowField1Value != ""
      ) {
        counter++;
      }
      if (
        this.evData.google.secondRowField2Label != "" &&
        this.evData.google.secondRowField2Value != ""
      ) {
        counter++;
      }

      if (counter == 1)
        return "d-flex justify-content-between m-0 dynamic-row-one-col";

      return "d-flex justify-content-between m-0";
    },
    startDate() {
      return moment(this.evData.google.secondRowField1Value).format(
        "DD MMM yyyy"
      );
    },
    startTime() {
      return moment(this.evData.google.secondRowField1Value).format("h:mm A");
    },
    club() {
      return this.$store.getters.clubDetails;
    },
  },
  mounted() {
    this.loader = true;
    this.evData.templateName = this.$route.query.title;
    this.evData.templateId = this.$route.query.template_id;
    if (this.$route.query.template_id != "0") {
      HTTP.get(
        `${configuration.apiBaseUrl}/api/v1/Pass/clubs/${this.$props.id}/templates/${this.$route.query.template_id}`
      )
        .then((response) => {
          this.loader = false;
          this.evData = response.data.data;
          if (
            this.evData.google.backgroundColor ||
            this.evData.apple.backgroundColor
          ) {
            this.OnChangesBGInitial(
              this.evData.google.backgroundColor,
              "google"
            );
            this.OnChangesBGInitial(
              this.evData.apple.backgroundColor,
              "appleBG"
            );
            this.OnChangesBGInitial(this.evData.apple.labelColor, "appleLabel");
            this.OnChangesBGInitial(this.evData.apple.textColor, "appleText");
          }

          if (this.evData.google.secondRowField1Value) {
            this.startDatePickerValue = new Date(
              this.evData.google.secondRowField1Value
            );
          }
          if (this.evData.google.expires) {
            this.endDatePickerValue = new Date(this.evData.google.expires);
          }

          if (this.evData.apple.expires) {
            this.endDatePickerValueApple = new Date(this.evData.apple.expires);
          }
          this.appleHandleThumbnailContainerClass();
        })
        .catch((error) => {
          this.loader = false;
        });
    } else {
      this.loader = false;
    }
  },
  methods: {
    OnChangesBGInitial(e, type) {
      if (e.value == "") {
        e.value = "#ffffff";
      }
      let brightness = this.brightnessByColor(e);
      if (brightness > 150) {
        if (type == "google") this.evData.google.googleTextColor = "#000000";
        if (type == "appleBG") this.evData.apple.appleBgTextColor = "#000000";
        if (type == "appleLabel")
          this.evData.apple.appleLabelTextColor = "#000000";
        if (type == "appleText") this.evData.apple.appleTextColor = "#000000";
      } else {
        if (type == "google") this.evData.google.googleTextColor = "#ffffff";
        if (type == "appleBG") this.evData.apple.appleBgTextColor = "#ffffff";
        if (type == "appleLabel")
          this.evData.apple.appleLabelTextColor = "#ffffff";
        if (type == "appleText") this.evData.apple.appleTextColor = "#ffffff";
      }
    },
    OnAppleChangesColorPicker(e, type) {
      if (e.value == "") {
        e.value = "#ffffff";
      }
      let brightness = this.brightnessByColor(e.value);
      if (type == "label") this.evData.apple.labelColor = e.value;
      if (type == "text") this.evData.apple.textColor = e.value;
      if (type == "bgColor") this.evData.apple.backgroundColor = e.value;
      if (brightness > 150) {
        if (type == "label") this.evData.apple.appleLabelTextColor = "#000000";
        if (type == "text") this.evData.apple.appleTextColor = "#000000";
        if (type == "bgColor") this.evData.apple.appleBgTextColor = "#000000";
      } else {
        if (type == "label") this.evData.apple.appleLabelTextColor = "#ffffff";
        if (type == "text") this.evData.apple.appleTextColor = "#ffffff";
        if (type == "bgColor") this.evData.apple.appleBgTextColor = "#ffffff";
      }
    },
    googleColorPickerChange(e) {
      this.evData.google.backgroundColor = e.sender.value();
      let brightness = this.brightnessByColor(e.sender.value());
      if (brightness > 150) {
        this.evData.google.googleTextColor = "#000000";
      } else {
        this.evData.google.googleTextColor = "#ffffff";
      }
    },
    brightnessByColor(color) {
      var color = "" + color,
        isHEX = color.indexOf("#") == 0,
        isRGB = color.indexOf("rgb") == 0;
      if (isHEX) {
        const hasFullSpec = color.length == 7;
        var m = color.substr(1).match(hasFullSpec ? /(\S{2})/g : /(\S{1})/g);
        if (m)
          var r = parseInt(m[0] + (hasFullSpec ? "" : m[0]), 16),
            g = parseInt(m[1] + (hasFullSpec ? "" : m[1]), 16),
            b = parseInt(m[2] + (hasFullSpec ? "" : m[2]), 16);
      }
      if (isRGB) {
        var m = color.match(/(\d+){3}/g);
        if (m)
          var r = m[0],
            g = m[1],
            b = m[2];
      }
      if (typeof r != "undefined") return (r * 299 + g * 587 + b * 114) / 1000;
    },
    onSelect(e) {
      this.selected = e.selected;
    },
    isClub() {
      return this.club.isAssociation ? "Association" : "Club";
    },

    googleSideBarOnSelect(e) {
      this.googleSideBarSelected = e.selected;
    },
    updateStartDatepicker(event) {
      this.evData.google.secondRowField1Value = moment(
        event.target.value
      ).toISOString();
      this.startDatePickerValue = event.target.value;
    },
    updateEndDatepicker(event) {
      this.evData.google.expires = moment(event.target.value).toISOString();
      this.endDatePickerValue = event.target.value;
    },

    updateEndDatepickerApple(event) {
      this.evData.apple.expires = moment(event.target.value).toISOString();
      this.endDatePickerValueApple = event.target.value;
    },
    googlePassHandleChange(e) {
      if (e.value == "second") {
        this.evData.google.thumbnail = "";
      }
      this.googlePassSelectedValue = e.value;
    },

    appleSideBarOnSelect(e) {
      this.appleSideBarSelected = e.selected;
    },
    appleHandleThumbnailContainerClass() {
      if (this.evData.apple.strip != "") {
        this.appleThumbnailContainerClass = "pass-date-time strip-image-wrap";
      } else {
        this.appleThumbnailContainerClass = "pass-date-time";
      }
    },
    applePassHandleChange(e) {
      this.evData.apple.thumbnail = "";
      this.evData.apple.strip = "";

      if (e.value == "second") {
        this.appleThumbnailContainerClass = "pass-date-time strip-image-wrap";
      } else {
        this.appleThumbnailContainerClass = "pass-date-time";
      }
      this.applePassSelectedValue = e.value;
    },
    barcodeHandleChange(e) {
      this.evData.barcodeType = e.value;
    },
    toDigitalPassTemp() {
      if (this.isFromManageRecipients) {
        this.navigateToManageRecipients();
      } else {
        this.navigateToVoucherHomepage();
      }
    },
    navigateToManageRecipients() {
      this.$router.push({
        name: "AddRecipients",
        query: {
          template_id: this.$route.query.template_id,
          templateType: this.$route.query.templateType,
          title: this.evData.templateName,
          type: this.$route.params.typeOfAction,
        },
      });
    },
    navigateToVoucherHomepage() {
      this.$router.push({
        name: "DigitalPass",
        query: {
          type: "1",
        },
      });
    },
    handleGoogleLogoUpload(e) {
      if (!e.target.files[0]["type"].includes("image")) {
        this.showNotification("Please upload valid image.", "warning");
        return;
      }
      const image = e.target.files[0];
      const size = (image.size / 1024 / 1024).toFixed(2);
      if (size < 0.1) {
        const reader = new FileReader();
        reader.readAsDataURL(image);
        reader.onload = (e) => {
          this.evData.google.logo = e.target.result;
        };
      } else {
        this.showNotification("Maximum size upload limit is 100kb", "warning");
      }
    },
    handleAppleLogoUpload(e) {
      if (!e.target.files[0]["type"].includes("image")) {
        this.showNotification("Please upload valid image.", "warning");
        return;
      }
      const image = e.target.files[0];
      const size = (image.size / 1024 / 1024).toFixed(2);
      if (size < 0.1) {
        const reader = new FileReader();
        reader.readAsDataURL(image);
        reader.onload = (e) => {
          this.evData.apple.logo = e.target.result;
        };
      } else {
        this.showNotification("Maximum size upload limit is 100kb", "warning");
      }
    },
    handleGoogleThumbnailUpload(e) {
      if (!e.target.files[0]["type"].includes("image")) {
        this.showNotification("Please upload valid image.", "warning");
        return;
      }
      const image = e.target.files[0];
      const size = (image.size / 1024 / 1024).toFixed(2);
      if (size < 0.1) {
        const reader = new FileReader();
        reader.readAsDataURL(image);
        reader.onload = (e) => {
          this.evData.google.thumbnail = e.target.result;
        };
      } else {
        this.showNotification("Maximum size upload limit is 100kb", "warning");
      }
    },
    handleAppleThumbnailUpload(e) {
      if (!e.target.files[0]["type"].includes("image")) {
        this.showNotification("Please upload valid image.", "warning");
        return;
      }
      const image = e.target.files[0];
      const size = (image.size / 1024 / 1024).toFixed(2);
      if (size < 0.1) {
        const reader = new FileReader();
        reader.readAsDataURL(image);
        reader.onload = (e) => {
          this.evData.apple.thumbnail = e.target.result;
        };
      } else {
        this.showNotification("Maximum size upload limit is 100kb", "warning");
      }
    },
    handleAppleStripUpload(e) {
      if (!e.target.files[0]["type"].includes("image")) {
        this.showNotification("Please upload valid image.", "warning");
        return;
      }
      const image = e.target.files[0];
      const size = (image.size / 1024 / 1024).toFixed(2);
      if (size < 0.1) {
        const reader = new FileReader();
        reader.readAsDataURL(image);
        reader.onload = (e) => {
          this.evData.apple.strip = e.target.result;
        };
      } else {
        this.showNotification("Maximum size upload limit is 100kb", "warning");
      }
    },
    applePreviewOnSelect(e) {
      this.applePreviewSelected = e.selected;
    },
    savePassData() {
      if (this.dataValidation()) {
        this.loader = true;
        this.submitDisable = true;
        let payload = { ...this.evData, clubId: this.$route.params.id };

        HTTP.post(
          `${configuration.apiBaseUrl}/api/v1/Pass/clubs/${this.$props.id}/templates/membership-card`,
          payload
        )
          .then((response) => {
            this.loader = false;
            this.submitDisable = false;
            this.evData.templateId = response.data.data.templateId;
            this.showNotification(
              this.$route.query.template_id == 0
                ? "Saved Successfully."
                : "Digital Pass template has been updated.",
              "success"
            );
            this.$router.push({
              name: "EditPass",
              query: {
                template_id: response.data.data.templateId,
                templateType: this.$route.query.templateType,
                title: this.evData.templateName,
              },
            });
          })
          .catch((error) => {
            this.loader = false;
            this.submitDisable = false;
            this.showNotification("Failed to save", "error");
          });
      }
    },
    openTestEmailModal() {
      this.testEmailModalVisible = true;
    },
    closeTestEmailModal() {
      this.testEmailModalVisible = false;
    },
    modalHandler(data) {
      this.emailErrorMessage = "";
      this.closeTestEmailModal();
    },
    dataValidation() {
      if (this.evData.google.header == "") {
        this.showNotification("Google: Organization name missing", "warning");
        return false;
      }
      if (this.evData.google.header.length > 50) {
        this.showNotification(
          "Google: Organization name can not exceed 50 characters",
          "warning"
        );
        return false;
      }
      if (this.evData.google.subHeader == "") {
        this.showNotification("Google: Sub-Header Missing", "warning");
        return false;
      }
      if (this.evData.google.subHeader.length > 50) {
        this.showNotification(
          "Google: Sub-Header can not exceed 50 characters",
          "warning"
        );
        return false;
      }

      if (this.evData.google.secondRowField1Label.length > 50) {
        this.showNotification(
          "Google: Second row First Label can not exceed 50 characters",
          "warning"
        );
        return false;
      }
      if (this.evData.google.secondRowField1Value.length > 50) {
        this.showNotification(
          "Google: Second row First Value can not exceed 50 characters",
          "warning"
        );
        return false;
      }
      if (this.evData.google.secondRowField2Label.length > 50) {
        this.showNotification(
          "Google: Second row Second Label can not exceed 50 characters",
          "warning"
        );
        return false;
      }
      if (this.evData.google.secondRowField2Value.length > 50) {
        this.showNotification(
          "Google: Second row Second Value can not exceed 50 characters",
          "warning"
        );
        return false;
      }

      if (this.evData.google.backRowField1Label.length > 50) {
        this.showNotification(
          "Google: Details First Field Label can not exceed 50 characters",
          "warning"
        );
        return false;
      }
      if (this.evData.google.backRowField1Value.length > 50) {
        this.showNotification(
          "Google: Details First Field Value can not exceed 50 characters",
          "warning"
        );
        return false;
      }
      if (this.evData.google.backRowField2Label.length > 50) {
        this.showNotification(
          "Google: Details Second Field Label can not exceed 50 characters",
          "warning"
        );
        return false;
      }
      if (this.evData.google.backRowField2Value.length > 50) {
        this.showNotification(
          "Google: Details Second Field Value can not exceed 50 characters",
          "warning"
        );
        return false;
      }
      if (this.evData.google.backRowField3Label.length > 50) {
        this.showNotification(
          "Google: Details Third Field Label can not exceed 50 characters",
          "warning"
        );
        return false;
      }
      if (this.evData.google.backRowField3Value.length > 50) {
        this.showNotification(
          "Google: Details Third Field Value can not exceed 50 characters",
          "warning"
        );
        return false;
      }

      if (this.evData.apple.primaryField1Label.length > 50) {
        this.showNotification(
          "Apple: Primary Field Label can not exceed 50 characters",
          "warning"
        );
        return false;
      }
      if (this.evData.apple.primaryField1Value.length > 50) {
        this.showNotification(
          "Apple: Primary Field Value can not exceed 50 characters",
          "warning"
        );
        return false;
      }

      if (this.evData.apple.secondaryField1Label.length > 50) {
        this.showNotification(
          "Apple: Secondary First Field Label can not exceed 50 characters",
          "warning"
        );
        return false;
      }
      if (this.evData.apple.secondaryField1Value.length > 50) {
        this.showNotification(
          "Apple: Secondary First Field Value can not exceed 50 characters",
          "warning"
        );
        return false;
      }
      if (this.evData.apple.secondaryField2Label.length > 50) {
        this.showNotification(
          "Apple: Secondary Second Field Label can not exceed 50 characters",
          "warning"
        );
        return false;
      }
      if (this.evData.apple.secondaryField2Value.length > 50) {
        this.showNotification(
          "Apple: Secondary Second Field Value can not exceed 50 characters",
          "warning"
        );
        return false;
      }
      if (this.evData.apple.secondaryField3Label.length > 50) {
        this.showNotification(
          "Apple: Secondary Third Field Label can not exceed 50 characters",
          "warning"
        );
        return false;
      }
      if (this.evData.apple.secondaryField3Value.length > 50) {
        this.showNotification(
          "Apple: Secondary Third Field Value can not exceed 50 characters",
          "warning"
        );
        return false;
      }

      if (this.evData.apple.auxiliaryField1Label.length > 50) {
        this.showNotification(
          "Apple: Auxiliary First Field Label can not exceed 50 characters",
          "warning"
        );
        return false;
      }
      if (this.evData.apple.auxiliaryField1Value.length > 50) {
        this.showNotification(
          "Apple: Auxiliary First Field Value can not exceed 50 characters",
          "warning"
        );
        return false;
      }
      if (this.evData.apple.auxiliaryField2Label.length > 50) {
        this.showNotification(
          "Apple: Auxiliary Second Field Label can not exceed 50 characters",
          "warning"
        );
        return false;
      }
      if (this.evData.apple.auxiliaryField2Value.length > 50) {
        this.showNotification(
          "Apple: Auxiliary Second Field Value can not exceed 50 characters",
          "warning"
        );
        return false;
      }
      if (this.evData.apple.auxiliaryField3Label.length > 50) {
        this.showNotification(
          "Apple: Auxiliary Third Field Label can not exceed 50 characters",
          "warning"
        );
        return false;
      }
      if (this.evData.apple.auxiliaryField3Value.length > 50) {
        this.showNotification(
          "Apple: Auxiliary Third Field Value can not exceed 50 characters",
          "warning"
        );
        return false;
      }

      if (this.evData.apple.backField1Label.length > 50) {
        this.showNotification(
          "Apple: Back First Field Label can not exceed 50 characters",
          "warning"
        );
        return false;
      }
      if (this.evData.apple.backField1Value.length > 50) {
        this.showNotification(
          "Apple: Back First Field Value can not exceed 50 characters",
          "warning"
        );
        return false;
      }
      if (this.evData.apple.backField2Label.length > 50) {
        this.showNotification(
          "Apple: Back Second Field Label can not exceed 50 characters",
          "warning"
        );
        return false;
      }
      if (this.evData.apple.backField2Value.length > 50) {
        this.showNotification(
          "Apple: Back Second Field Value can not exceed 50 characters",
          "warning"
        );
        return false;
      }
      if (this.evData.apple.backField3Label.length > 50) {
        this.showNotification(
          "Apple: Back Third Field Label can not exceed 50 characters",
          "warning"
        );
        return false;
      }
      if (this.evData.apple.backField3Value.length > 50) {
        this.showNotification(
          "Apple: Back Third Field Value can not exceed 50 characters",
          "warning"
        );
        return false;
      }

      if (this.evData.google.logo == "") {
        this.showNotification("Google: Logo missing", "warning");
        return false;
      }
      if (this.evData.apple.logo == "") {
        this.showNotification("Apple: Logo missing", "warning");
        return false;
      }
      if (this.evData.apple.logoText == "") {
        this.showNotification("Apple: Logo Text missing", "warning");
        return false;
      }
      if (this.evData.apple.logoText.length > 50) {
        this.showNotification(
          "Apple: Logo Text can not exceed 50 characters",
          "warning"
        );
        return false;
      }

      if (this.evData.apple.description == "") {
        this.showNotification("Apple: Description missing", "warning");
        return false;
      }
      if (this.evData.apple.description.length > 50) {
        this.showNotification(
          "Apple: Description can not exceed 50 characters",
          "warning"
        );
        return false;
      }
      if (this.evData.apple.organizationName == "") {
        this.showNotification("Apple: Organization Name missing", "warning");
        return false;
      }
      if (this.evData.apple.organizationName.length > 50) {
        this.showNotification(
          "Apple: Organization Name can not exceed 50 characters",
          "warning"
        );
        return false;
      }

      if (this.evData.apple.headerField1Label.length > 50) {
        this.showNotification(
          "Apple: Header Field Label can not exceed 50 characters",
          "warning"
        );
        return false;
      }
      if (this.evData.apple.headerField1Value.length > 50) {
        this.showNotification(
          "Apple: Header Field Value can not exceed 50 characters",
          "warning"
        );
        return false;
      }

      if (isPastDate(this.endDatePickerValue)) {
        this.showNotification(
          "Google: Invalid expiry date. Select present date or a future date.",
          "warning"
        );
        return false;
      }

      if (isPastDate(this.endDatePickerValueApple)) {
        this.showNotification(
          "Apple: Invalid expiry date. Select present date or a future date.",
          "warning"
        );
        return false;
      }

      return true;
    },
    showNotification(message, type) {
      this.notifyMessage = message;
      this.notifyType = type;
      this.isNotify = true;
      setTimeout(
        () => (
          (this.notifyMessage = ""),
          (this.notifyType = "success"),
          (this.isNotify = false)
        ),
        2000
      );
    },
    submitTestEmail() {
      if (!this.evData.receiverEmail) {
        this.emailErrorMessage = "Email Address is required.";
        return;
      }
      if (!emailRegex.test(this.evData.receiverEmail)) {
        this.emailErrorMessage = "Please enter a valid email.";
        return;
      }
      this.closeTestEmailModal();
      if (this.dataValidation()) {
        this.loader = true;
        let payload = this.evData;
        HTTP.post(
          `${configuration.apiBaseUrl}/api/v1/Pass/generate/membership-card`,
          payload
        )
          .then((response) => {
            this.loader = false;
            this.showNotification("Successfully sent email", "success");
          })
          .catch((error) => {
            this.loader = false;
            this.showNotification("Failed to send email.", "error");
          });
      }
    },
    removeAppleImages(data) {
      this.evData.apple[data] = "";
    },
    removeGoogleImages(data) {
      this.evData.google[data] = "";
    },
  },
};
</script>

<style scoped></style>
