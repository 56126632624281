<div class="dashboard-right-inner">
    <!--breadcrumb-->
    <Breadcrumb :id="$route.params.id" />


    <div class="dashboard-content p-0">
        <div>
            <EventTicket :id="id" :template_id="template_id" :title="title" v-if="type == '3'" />
            <MembershipCard :id="id" :template_id="template_id" :title="title" v-if="type == '2'" />
            <LoyaltyCard :id="id" :template_id="template_id" :title="title" v-if="type == '1'" />
        </div>
    </div>


</div>